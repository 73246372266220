module services {
    export module costing {

        export class costParameterSupplierService implements interfaces.costing.ICostParameterSupplierService {

            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getSupplierList(): ng.resource.IResourceClass<interfaces.costing.ICostSupplier> {
                return this.$resource<interfaces.costing.ICostSupplier>(this.ENV.DSP_URL + "CostParameterSupplier/GetSupplierList", {
                    showAllActive: '@showAllActive',
                    entityId: '@entityId',
                    supplierId: '@supplierId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            saveSupplier(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterSupplier/SaveSupplier", {
                });
            }

            removeSupplier(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterSupplier/RemoveSupplier", {
                    csuId: '@csuId',
                });
            }


        }

    }

    angular.module("app").service("costParameterSupplierService", services.costing.costParameterSupplierService);
}